body {
    font-family: sans-serif;
}

.ais-SearchBox {
    margin: 1em 0;
}

.ais-Pagination {
    margin-top: 1em;
}

.ais-InstantSearch {
    max-width: 960px;
    overflow: hidden;
    margin: 0 auto;
}

.ais-InfiniteHits-item {
    margin-bottom: 1em;
    width: calc(50% - 1rem);
}

.ais-InfiniteHits-sentinel {
    width: 100%;
}

.ais-InfiniteHits-item img {
    margin-right: 1em;
}

.hit-name {
    margin-bottom: 0.5em;
    font-weight: bold;
}

.hit-description {
    margin-bottom: 0.5em;
}
