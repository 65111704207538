.App {
  text-align: center;
}

.image-container {
  max-width: 300px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 600px){
  .description {
    position: absolute;
    overflow: hidden;
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header .content {
  width: 50%;
}

.App-header .content div {
  width: 100%;
  display: block;
  color: black;
}

.btn {
  cursor: pointer;
  position: relative;
  background: #daf5cc;
  font-size: calc(0.6vw + 0.6vh + 0.6vmin);
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 0.5s;
  margin: 30px;
}
.btn:after, .btn:before {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  border: 0px solid #fff;
  transition: all 0.5s;
}
.btn:after {
  top: -1px;
  left: -1px;
  border-top: 5px solid #2c5d47;
  border-left: 5px solid #2c5d47;
}
.btn:before {
  bottom: -1px;
  right: -1px;
  border-bottom: 5px solid #2c5d47;
  border-right: 5px solid #2c5d47;
}
.btn:hover {
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.btn:hover:before, .btn:hover:after {
  width: 100%;
  height: 100%;
}

.btn:active {
  transform: translateY(4px);
}

.frog-border {
  margin-bottom: 0;
  margin-top: 10px;
}

.App-header .twitter {
  background-color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pfp img {
  display: block;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 70px;
  position: relative;
  margin: 0px auto;
  border: 3px solid #fff
}

.content p {
  font-size: 17px;
  color: black;
  background-color: #daf5cc;
  margin-top: -7px;
  margin-left: 30px;
  margin-right: 30px;
  display: block;
  padding: 10px 40px;
  text-decoration: none;
  outline: none;
  border: 1px solid black;
}

.navBar {
  position: relative;
}

.navBar button {
  position: fixed;
  left: 40px;
  top: 40px;
  z-index: 10;
  cursor: pointer;
  /* addition */
  background: transparent;
  border: none;
  outline: none;
}

.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: darkcyan;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 290px;
  z-index: 9;
}

.menuNav.showMenu {
  width: 100%;
}

a {
  display: block;
  padding: 10px 40px;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  outline: none;
}

.navBar a {
  text-transform: uppercase;
}

.menuNav li:first-child {
  margin-top: 7rem;
}
